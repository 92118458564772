import { GoogleMap, InfoBox, Marker, useJsApiLoader } from '@react-google-maps/api';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { mapStyle } from './MapStyle';
import './Map.scss';
import Box from '@mui/material/Box';
import { IconButton, Typography } from '@mui/material';
import {
  ClassifierReadView,
  GroupMembershipReadView,
  GroupSummaryView,
  MAP_LIBRARIES,
  OrganisationSummaryView
} from '../../types/types';

import markerPerson from '../../utils/img/markerPerson.png';
import { addVerificationBadgeToIcon, getPinSize, useWindowSize } from '../../utils/HelperFunctions';
import CloseIcon from '@mui/icons-material/HighlightOff';
import { grey } from '@mui/material/colors';
import ComponentLoader, { componentLoaderStyles } from '../common/componentLoader/ComponentLoader';
import { ENV_VARIABLES } from '../../config';
import LoaderImg from '../../utils/img/AppTrac.svg';
import { getGroupByShortName, getOrganisationsForGroup, getOrganisationSummariesForGroup } from '../../services/Groups';
import { toast } from 'react-toastify';
import { getClassifiers } from '../../services/Classifier';
import { useGlobalState } from '../../context/GlobalState';
import { useLocation } from 'react-router-dom';
import MapInfoBox from '../common/MapInfoBox';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';

type Props = {
  data?: OrganisationSummaryView[];
  disableOverLay?: boolean;
  initialSearch?: string;
};

interface GroupMembershipReadViewAlt extends GroupMembershipReadView {
  verifiedIcon?: string; // Base64 string
}
interface GroupSummaryViewAlt extends GroupSummaryView {
  verifiedIcon?: string; // Base64 string
}

const drawCircle = (
  context: CanvasRenderingContext2D,
  color: string | CanvasGradient,
  x: number,
  y: number,
  radius: number,
  fill: boolean = true
) => {
  context.beginPath();
  context.arc(x, y, radius, 0, 2 * Math.PI);
  if (fill) {
    context.fillStyle = color;
    context.fill();
  } else {
    context.strokeStyle = color;
    context.stroke();
  }
  context.closePath();
};

const drawOuterRing = (ctx: CanvasRenderingContext2D, x: number, y: number, radius: number) => {
  // Draw the outer blue ring
  ctx.beginPath();
  ctx.arc(x, y, radius, 0, Math.PI * 2, false);
  ctx.fillStyle = 'white';
  ctx.fill();
  ctx.lineWidth = 2;
  ctx.strokeStyle = '#05c3f7';
  ctx.stroke();
  ctx.closePath();
};

const ICON_SIZE = 17;
const VERIFIED_ICON_SIZE = 28;

const GroupMapScreen = (props: Props) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({ lat: 59.2781126, lng: 15.1674353 });
  const [userPosition, setUserPosition] = useState<GeolocationCoordinates>();
  const [isStreetView, setIsStreetView] = useState<boolean>(false);
  const [streetView, setStreetView] = useState<boolean>(false);
  const [showingUserTooltip, setShowingUserTooltip] = useState<boolean>(false);
  const [organisations, setOrganisations] = useState<OrganisationSummaryView[]>([]);
  // Due to the changes in getIcon function, we don't need this state anymore. Revert if needed.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [groupMemberships, setGroupMemberships] = useState<GroupMembershipReadViewAlt[]>([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState<OrganisationSummaryView>();
  const [classifiers, setClassifiers] = useState<Map<number, ClassifierReadView>>(new Map());
  const [group, setGroup] = useState<GroupSummaryViewAlt>();
  const [loading, setLoading] = useState<boolean>();
  const [visibleOrganisations, setVisibleOrganisations] = React.useState<OrganisationSummaryView[]>([]);
  const location = useLocation();
  const globalCtx = useGlobalState();
  const { isMobileDevice } = useWindowSize();
  const [dotIcon, setDotIcon] = useState<string>('');
  const [dotIconVerified, setDotIconVerified] = useState<string>('');
  const [threshold, setThreshold] = useState<number>(20);

  const globalState = useGlobalState();

  const canvas1 = document.createElement('canvas');
  const ctx1 = canvas1.getContext('2d');
  canvas1.width = ICON_SIZE;
  canvas1.height = ICON_SIZE;
  const radius = ICON_SIZE / 2;
  /**CANVAS FOR VERIFIED SIMPLE ICONS */
  const canvas2 = document.createElement('canvas');
  const ctx2 = canvas2.getContext('2d');
  canvas2.width = VERIFIED_ICON_SIZE;
  canvas2.height = VERIFIED_ICON_SIZE;

  useEffect(() => {
    if (ctx1) {
      drawCircle(ctx1, group?.themeColor ?? '#fff', radius, radius, radius * 0.5);
      drawCircle(ctx1, 'white', radius, radius, radius * 0.5, false);
      setDotIcon(canvas1.toDataURL());
      ctx1.clearRect(0, 0, ICON_SIZE, ICON_SIZE);
    }
    if (ctx2) {
      drawOuterRing(ctx2, radius, radius, radius - 2);
      drawCircle(ctx2, group?.themeColor ?? '#fff', radius, radius, radius * 0.5);
      setDotIconVerified(canvas2.toDataURL());
      ctx2.clearRect(0, 0, VERIFIED_ICON_SIZE, VERIFIED_ICON_SIZE);
    }
  }, [canvas1, canvas2, ctx1, ctx2, group?.themeColor, radius]);
  useEffect(() => {
    setLoading(true);
    const groupShortName = window.location.pathname.split('/group/')[1];
    fetchData(groupShortName)
      .catch((reason) => {
        toast.error('Ett fel inträffade.');
      })
      .finally(() => setLoading(false));
    const id = navigator.geolocation.watchPosition(
      (position) => {
        setUserPosition(position.coords);
      },
      (error) => {
        console.log('Position watch error:', error);
      },
      { enableHighAccuracy: false, timeout: 5000, maximumAge: 0 }
    );
    return () => {
      navigator.geolocation.clearWatch(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBoundsChanged = useCallback(() => {
    if (!map) return; // Make sure we have the map instance

    // Get the current map bounds
    const bounds = map.getBounds();
    if (!bounds) return;

    // Filter organisations that are within these bounds
    const filtered = organisations.filter((org) => bounds.contains({ lat: org.lat, lng: org.lng }));

    setVisibleOrganisations(filtered);
  }, [map, organisations]);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ENV_VARIABLES.googleMapsApiKey!,
    libraries: MAP_LIBRARIES
  });

  const generateCategoriesWithBlueTick = async (data: ClassifierReadView[]) => {
    const newData = await Promise.all(
      data.map(async (d) => {
        const verifiedIcon = d.icon ? await addVerificationBadgeToIcon(d.icon) : '';
        return { ...d, verifiedIcon };
      })
    );
    return newData;
  };

  const generateMembersWithBlueTick = async (data: GroupMembershipReadView[]) => {
    const newData = await Promise.all(
      data.map(async (d) => {
        const verifiedIcon = d.icon ? await addVerificationBadgeToIcon(d.icon) : '';
        return { ...d, verifiedIcon };
      })
    );
    return newData;
  };

  const generateGroupWithBlueTick = async (data: GroupSummaryView): Promise<GroupSummaryViewAlt> => {
    const verifiedIcon = data.icon ? await addVerificationBadgeToIcon(data.icon) : '';
    return { ...data, verifiedIcon };
  };

  const fetchData = async (shortName: string) => {
    try {
      const group = await getGroupByShortName(shortName);
      globalCtx.setTheme({ primaryColor: group.themeColor, logo: group.logo });
      const members = await getOrganisationsForGroup(group.id);
      // CHANGED logic according to https://gitlab.softwerk.se/trac-technology/web/-/issues/283
      // Instead of Promise.allSettled, we use the new endpoint
      // v1/group/{groupId}/org-summaries in getOrganisationSummariesForGroup
      /*  const _orgResults = await Promise.allSettled(
        members.map((membership) => getOrganisationSummary(membership.organisationId))
      );
      const failedResults = _orgResults.filter(
        (result): result is PromiseRejectedResult => result.status === 'rejected'
      );
      if (failedResults.length > 0) {
        console.log('[GroupMapScreen] Failed to fetch organisation summary for some organisations:', failedResults);
      }
      const _organisations = _orgResults
        .filter((result): result is PromiseFulfilledResult<OrganisationSummaryView> => result.status === 'fulfilled')
        .map((result) => result.value);  */

      const _organisations = await getOrganisationSummariesForGroup(group.id);

      const _classifiers = await getClassifiers();
      const _classifiersWithBlueTick = await generateCategoriesWithBlueTick(_classifiers.records);
      setClassifiers(new Map(_classifiersWithBlueTick.map((classifier) => [classifier.id, classifier])));

      const _membersWithBlueTick = await generateMembersWithBlueTick(members);
      setGroupMemberships(_membersWithBlueTick);

      const _groupWithBlueTick = await generateGroupWithBlueTick(group);
      setGroup(_groupWithBlueTick);

      setOrganisations(_organisations);
    } catch (e) {
      console.log('[GroupMap] fetchData failed:', e);
    }
  };
  const onLoad = useCallback(
    (map: google.maps.Map) => {
      //Set the bounds to the furthest north and south points on the map in order to avoid zooming out or panning further than those bounds
      const maxBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(-85, -180),
        new google.maps.LatLng(85, 180)
      );

      const params = new URLSearchParams(location.search);

      const zoomString = params.get('zoom');
      var zoom;
      if (zoomString !== null) {
        zoom = parseInt(zoomString);
      }

      map.setOptions({
        zoom: Number.isNaN(zoom) ? 7 : zoom ?? 7,
        controlSize: 30,
        restriction: {
          latLngBounds: maxBounds,
          strictBounds: true
        },
        fullscreenControl: false,
        mapTypeControl: false,
        styles: mapStyle
      });
      map.setTilt(45);

      map.getStreetView().addListener('visible_changed', () => {
        setStreetView(map.getStreetView().getVisible());
        console.log(`street viewed? ${streetView}`);
      });

      map.getStreetView().addListener('visible_changed', () => {
        setStreetView(map.getStreetView().getVisible());
        console.log(`street viewed? ${streetView}`);
      });

      setMap(map);
    },
    [streetView, location]
  );

  const onUnmount = useCallback((map: google.maps.Map) => {
    setMap(null);
  }, []);

  const getIcon = useCallback(
    (organisation: OrganisationSummaryView) => {
      // CHANGED logic according to https://gitlab.softwerk.se/trac-technology/web/-/issues/282
      /*
      const membership = groupMemberships.find((membership) => membership.organisationId === organisation.id);
      if (membership && membership.icon)
        return organisation.customerCardEnabled ? membership.verifiedIcon : membership.icon;
      */

      const organisationCategories = organisation.classifierIds.map((id) => classifiers.get(id));
      const topCategory =
        organisationCategories.find((classifier) => classifier?.type === 'CATEGORY') ?? organisationCategories[0];
      if (topCategory && topCategory.icon)
        return organisation.customerCardEnabled ? topCategory.verifiedIcon : topCategory.icon;
      return organisation.customerCardEnabled ? group?.verifiedIcon : group?.icon;
    },
    [group, classifiers]
  );

  useEffect(() => {
    let streetViewChangeListener: google.maps.MapsEventListener | null = null;
    if (map) {
      streetViewChangeListener = google.maps.event.addListener(map.getStreetView(), 'visible_changed', () => {
        setIsStreetView(map.getStreetView().getVisible());
      });
    }
    return () => {
      if (streetViewChangeListener && map) google.maps.event.removeListener(streetViewChangeListener);
    };
  }, [map]);

  const randomZIndexListRef = useRef<number[]>([]);

  useEffect(() => {
    const array: number[] = Array.from({ length: organisations?.length ?? 0 }, (_, index) => index);
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    randomZIndexListRef.current = array;
  }, [organisations]);

  useEffect(() => {
    const docRef = doc(globalState.fireStore, 'thresholds', 'groupMapScreen');
    (async () => {
      try {
        const docSnap = await getDoc(docRef);
        setThreshold(docSnap.data()?.threshold ?? 20);
      } catch (e) {
        console.error("getDoc failed for 'thresholds/groupMapScreen':", e);
        setThreshold(20);
      }
    })();

    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        setThreshold(docSnap.data()?.threshold ?? 20);
      },
      (error) => {
        console.error("onSnapshot failed for 'thresholds/groupMapScreen':", error);
        setThreshold(20);
      }
    );

    return () => unsubscribe();
  }, [globalState.fireStore]);

  const getMarkerIcon = useCallback(
    (org: OrganisationSummaryView): google.maps.Icon | google.maps.Symbol => {
      if (visibleOrganisations.length > threshold) {
        // Return a small colored circle (using a Symbol)
        return org.customerCardEnabled ? { url: dotIconVerified } : { url: dotIcon };
      }
      // Otherwise, return your existing icon logic.
      return {
        url: getIcon(org) ?? '', // your function or fallback
        scaledSize: isStreetView ? new google.maps.Size(65, 75) : getPinSize(location, org.customerCardEnabled)
      };
    },
    [dotIcon, dotIconVerified, getIcon, isStreetView, location, threshold, visibleOrganisations.length]
  );
  return loadError ? (
    <Box sx={componentLoaderStyles.fullCentered}>
      <Typography variant='h6' color={grey[100]}>
        Map Loading Error!
      </Typography>
      <Typography variant='body1' color={grey[100]}>
        Please refresh the page!
      </Typography>
    </Box>
  ) : !isLoaded || loading ? (
    <ComponentLoader bgColor='#02636e' bgImg={LoaderImg} />
  ) : (
    <>
      {isMobileDevice && selectedOrganisation && (
        <MapInfoBox
          organisationSummary={selectedOrganisation}
          onClose={() => setSelectedOrganisation(undefined)}
          isMobileDevice={isMobileDevice}
          forceEnableHomePageButton={true}
        />
      )}
      <GoogleMap
        id='react-google-maps'
        mapContainerClassName={'google-map-container-style'}
        onLoad={onLoad}
        onUnmount={onUnmount}
        center={mapCenter}
        onBoundsChanged={handleBoundsChanged}
      >
        {!isMobileDevice && selectedOrganisation && (
          <MapInfoBox
            organisationSummary={selectedOrganisation}
            onClose={() => setSelectedOrganisation(undefined)}
            isMobileDevice={isMobileDevice}
            forceEnableHomePageButton={true}
          />
        )}
        {userPosition && (
          <Marker
            position={{ lat: userPosition!.latitude, lng: userPosition!.longitude }}
            icon={{
              url: markerPerson,
              scaledSize: new google.maps.Size(40, 40)
            }}
            onClick={() => {
              setShowingUserTooltip((prev) => !prev);
            }}
          >
            {showingUserTooltip && (
              <InfoBox
                options={{
                  enableEventPropagation: true,
                  closeBoxURL: '',
                  boxStyle: {
                    boxShadow: ' 8px 8px 41px -15px rgba(0,0,0,0.75)',
                    backgroundColor: 'rgba(255,255,255,0)'
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    padding: '1.5rem .5rem',
                    borderRadius: '1rem',
                    minWidth: isMobileDevice ? 'calc(window.innerWidth - 2rem)' : '25%'
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant='body2' align='left' noWrap>
                      Du är här
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      aria-label='close button'
                      onClick={() => setSelectedOrganisation(undefined)}
                      sx={{ padding: 0, marginLeft: 2 }}
                    >
                      <CloseIcon sx={{ color: grey[600] }} fontSize='small' />
                    </IconButton>
                  </Box>
                </Box>
              </InfoBox>
            )}
          </Marker>
        )}
        {organisations &&
          organisations.map((summary, index) => (
            <Marker
              key={`${summary.id}-${index}`}
              position={{ lat: summary.lat, lng: summary.lng }}
              zIndex={summary.customerCardEnabled ? 9999 : randomZIndexListRef.current[index]}
              icon={getMarkerIcon(summary)}
              onClick={() => {
                setSelectedOrganisation(summary);
              }}
              options={{ map: map }}
            />
          ))}
      </GoogleMap>
    </>
  );
};

export default GroupMapScreen;
